<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Escolas" :button="button">
     <v-container class="my-2">
        <v-row>
          <v-spacer />
          <v-col class="position-relative" cols="12">
            <v-text-field
            class="pb-4"
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar escolas"
              outlined
              dense
              background-color="transparent"
              hide-details
              @change="getClassData()"
              @click:append="getClassData()"
            ></v-text-field>
            <div class="hint">Aperte ENTER para pesquisar</div>
          </v-col>
        </v-row>
     </v-container>
      <datatable-list
        :text="text"
        :headers="headers"
        :sortBy="sortBy"
        :items="items"
        v-if="schoolFetch"
        :showSelect="showSelect"
        :displayActionItems="displayActionItems"
        :tableUser="true"
        :pagination="pagination"
        :loading="!schoolFetch"
        @update="pagination = { ...pagination, ...$event}"
      ></datatable-list>
      <v-skeleton-loader
        v-for="i in 5"
        v-else
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
    </base-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  data: () => ({
    organizationId: '',
    page: {
      title: 'Escolas'
    },
    schoolFetch: false,
    isSearching: false,
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        to: '/school'
      }
    ],
    button: {
      show: false,
      url: '/school/add',
      text: 'Escola',
      icon: 'mdi-plus'
    },
    text: {
      searchLabel: 'Pesquisar Escolas',
      emptyLabel: 'Nenhuma escola encontrada',
      addLabel: 'Adicionar Escola',
      addUrl: '/school/add',
      importLabel: 'Importar Escolas',
      importUrl: '/school/add'
    },
    headers: [
      {
        text: 'Nome da Escola',
        value: 'name',
        align: 'start'
      },
      { text: 'Cidade', value: 'city', align: 'start' },
      { text: 'Ações', value: 'actions', align: 'end', sortable: false }
    ],
    sortBy: '',
    search: '',
    _timerId: null,
    showSelect: false,
    displayActionItems: false,
    items: [],
    totalNumberOfItems: 0,
    domains: [],
    pagination: {
      page: 1,
      itemsPerPage: 15,
      prev_page_url: false,
      next_page_url: false
    }
  }),
  methods: {
    buildQuery () {
      const pagination = `?page=${this.pagination.page}&per_page=${this.pagination.itemsPerPage}${this.search ? '&search=' + this.search : ''}`
      return pagination
    },
    formatSchool (schools) {
      const arr = []
      if (!schools.items.length) return arr
      schools.items.forEach((value, index) => {
        if (value) {
          const obj = {
            ...value.data,
            city: value.data.address.city,
            url: '/school/' + value.metadata.id,
            id: value.metadata.id,
            actions: [
              {
                title: 'Ver turmas',
                url: `/school/course/${value.metadata.id}`
              },
              {
                title: 'Ver diretores',
                url: `/school/list/${value.metadata.id}/principals`
              },
              {
                title: 'Ver coordenadores',
                url: `/school/list/${value.metadata.id}/coordinators`
              },
              {
                title: 'Gerenciar diretores',
                url: `/school/people/${value.metadata.id}/principals`
              },
              {
                title: 'Gerenciar coordenadores',
                url: `/school/people/${value.metadata.id}/coordinators`
              }
            ]
          }
          arr.push(obj)
        }
      })
      return arr
    },
    async getClassData () {
      this.schoolFetch = false
      this.isSearching = true

      try {
        const classResponse = await this.$axios.get(
          `/organizations/${this.organizationId}/schools${this.buildQuery()}`
        )

        if (!classResponse.data) {
          throw classResponse
        }

        this.items = this.formatSchool(classResponse.data)
        const pagination = classResponse.data.pagination
        this.pagination = {
          ...this.pagination,
          total: pagination.total,
          page: pagination.current_page,
          itemsPerPage: pagination.per_page
        }
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {
        this.schoolFetch = true
        this.isSearching = false
      }
    }
  },
  watch: {
    async 'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        await this.getClassData()
      }
    }
  },
  async mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.button.show = (this.user.level > 1)
    if (id) {
      this.organizationId = id
      await this.getClassData()
    } else {
      this.$store.dispatch('alert', {
        color: 'red',
        msg: 'Você precisa selecionar uma organização'
      })
    }
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>
